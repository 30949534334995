import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import scrollTo from 'commons/scrollTo';
import isClient from 'commons/isClient';

const ScrollToTop = ({ delay = 0 }) => {
  const location = useLocation();

  useEffect(() => {
    if (isClient()) {
      setTimeout(scrollTo(0, 0), delay);
    }
  }, [location.pathname, delay]);

  return null;
};

ScrollToTop.propTypes = {
  delay: PropTypes.number,
};

export default ScrollToTop;

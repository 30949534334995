import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { blogCategories } from 'lib/contentful-client';

const Nav = ({ blogUrl, slug }) => (
  <nav className="flex items-baseline [border-bottom:1px_solid_#f1f1f1] lg:pl-[3.333vw] lg:pr-[3.333vw]">
    <Link to={`/${blogUrl}`}>
      <p className="text-xl font-bold text-gray items-center hidden h-[58px] m-0 lg:flex lg:h-20 lg:mr-[4vw]">
        The Blog
      </p>
    </Link>

    <ul className="flex h-full overflow-x-auto m-0 pl-3 pr-3 lg:mx-4 lg:my-0 lg:p-0 [&::-webkit-scrollbar]:hidden">
      {blogCategories.map(nav => (
        <li
          key={`${nav.slug}-navlink`}
          className="text-base flex flex-shrink-0 items-center justify-center font-bold no-underline cursor-pointer h-[58px] lg:h-20 [&_a]:[border-bottom:1px_solid_transparent] [&_a]:items-center [&_a]:content-center [&_a]:h-full [&_a]:transition-[all_.15s_ease-in] [&_a]:pl-3 [&_a]:pr-3 [&_a]:mb-0.5"
        >
          <Link
            to={`/${blogUrl}${nav.slug ? `${nav.slug}/` : ''}`}
            className={classNames(
              'flex hover:text-brand hover:shadow-[0_1px_0_0_theme(colors.brand.DEFAULT)]',
              {
                'text-inherit': slug !== nav.slug,
                'text-brand shadow-[0_1px_0_0_theme(colors.brand.DEFAULT)]':
                  slug === nav.slug,
              }
            )}
          >
            {nav.text}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
);

Nav.propTypes = {
  blogUrl: PropTypes.string,
  slug: PropTypes.string,
};

export default Nav;

import { merge } from 'lodash';
import axios from 'axios';
import isClient from 'commons/isClient';
import { logError } from 'commons/logger';

import { createClient } from 'contentful';

const SPACE_ID =
  (isClient() &&
    window &&
    window._env_ &&
    (process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID ||
      window._env_.REACT_APP_CONTENTFUL_SPACE_ID)) ||
  '';
const ACCESS_TOKEN =
  (isClient() &&
    window &&
    window._env_ &&
    (process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN ||
      window._env_.REACT_APP_CONTENTFUL_ACCESS_TOKEN)) ||
  '';
const contentful_data_api = `${(isClient() &&
  window &&
  window._env_ &&
  (process.env.NEXT_PUBLIC_MICROSERVICE_DATA_API ||
    window._env_.REACT_APP_MICROSERVICE_DATA_API)) ||
  ''}/jb4/contentful`;

let client = null;

const contentfulClient = (envParams = null) => {
  if (!client) {
    client = createClient({
      space: envParams
        ? process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID ??
          envParams.REACT_APP_CONTENTFUL_SPACE_ID
        : SPACE_ID,
      accessToken: envParams
        ? process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN ??
          envParams.REACT_APP_CONTENTFUL_ACCESS_TOKEN
        : ACCESS_TOKEN,
    });
  }
  return client;
};

export const blogCategories = [
  {
    text: 'All',
    slug: '',
    is_category: false,
  },
  {
    text: 'Industry Insights',
    slug: 'industry-insights',
    is_category: true,
  },
  {
    text: 'Tips & Ideas',
    slug: 'tips-and-ideas',
    is_category: true,
  },
  {
    text: 'Style Stories',
    slug: 'style-stories',
    is_category: true,
  },
  {
    text: 'Before + After',
    slug: 'before-after',
    is_category: true,
  },
  {
    text: 'The Latest',
    slug: 'the-latest',
    is_category: false,
  },
  {
    text: 'Inspiring Spaces',
    slug: 'inspiring-spaces',
    is_category: true,
  },
];

export const getCategoryBySlug = slug =>
  blogCategories.find(category => category.slug === slug);
export const getPageTypeBySlug = slug => {
  if (slug === '') return 'home';
  return blogCategories.find(category => category.slug === slug)
    ? 'category'
    : 'single';
};

export const getEntries = (
  envParams = null,
  queryParams,
  adaptor = 'direct'
) => {
  const query = merge(
    {
      call_name: 'cda.getEntries',
      params: {
        content_type: 'blog',
        order: '-sys.createdAt',
        limit: 1,
        skip: 0,
      },
    },
    queryParams
  );
  let finalContentfulDataAPI = contentful_data_api;
  // Only true when rendered on the server
  if (envParams) {
    finalContentfulDataAPI = `${process.env.NEXT_PUBLIC_MICROSERVICE_DATA_API ??
      envParams.REACT_APP_MICROSERVICE_DATA_API}/jb4/contentful`;
  }
  if (adaptor === 'lambda') {
    return new Promise((resolve, reject) => {
      axios
        .post(finalContentfulDataAPI, JSON.stringify(query), {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(result => {
          if (result.data) {
            resolve(result.data);
          } else {
            reject(new Error('Error while getting data from Contentful API'));
          }
        })
        .catch(error => {
          logError(error);
          reject(error);
        });
    });
  }
  return new Promise((resolve, reject) => {
    contentfulClient(envParams)
      .getEntries(query.params)
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
};

export const blogFields = (blog, blogUrl) => {
  if (!blog.items || (blog.items && blog.items.length < 1)) {
    return [];
  }

  return blog.items.map(entry => ({
    ...entry.fields,
    slug: `/${(blogUrl + entry.fields.slug).replace(/^\/+/g, '')}`,
  }));
};

export const buildGetEntriesQuery = (
  category,
  limit = 4,
  skip = 0,
  select = null,
  order = null
) => {
  const query = {
    params: {
      limit,
      skip,
      'fields.categories[in]': category?.text,
      select,
      order: !order ? '-sys.createdAt' : order,
    },
  };
  if (!category || category.slug === 'the-latest') {
    delete query.params['fields.categories[in]'];
  }

  if (select === null) {
    delete query.params.select;
  }

  return query;
};

export const fetchPostsByCategory = async (
  blogUrl,
  category,
  limit = 4,
  skip = 0,
  select = null
) => {
  const query = buildGetEntriesQuery(category, limit, skip, select);
  const entries = await getEntries(null, query);
  return {
    category,
    posts: blogFields(entries, blogUrl),
  };
};
